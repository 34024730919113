import React from "react"
import { headingFont } from "mill/utils/settings"
import { grayBackground } from "mill/utils/colors"
import { darken } from "polished"
import { md } from "mill/utils/breakpoints"
import styled from "styled-components"

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  background: ${darken(0.02, grayBackground)};
  border-top: 1px solid ${darken(0.06, grayBackground)};
  border-bottom: 1px solid ${darken(0.04, grayBackground)};
  margin: 0 -1.5rem;
  padding: 1rem 0.5rem;
  @media ${md} {
    border: none;
    padding: 0;
    margin: 0 -0.75rem;
    background: none;
  }
`

const Statistic = styled.div`
  background: white;
  border-radius: 3px;
  box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
  flex: 1;
  width: 100%;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  margin-bottom: 1rem;
  min-width: 15rem;

  @media ${md} {
    border: 2px solid rgb(228, 228, 228);
    box-shadow: none;
    padding: 0.5rem 1.5rem;
    margin: 0.75rem;
    min-width: 45%;
  }

  strong {
    font-family: ${headingFont};
    font-size: 13px;
    color: #8e9091;
    letter-spacing: 1.2px;
    text-transform: uppercase;
  }

  span {
    color: ${props => props.theme.colors.primary};
    font-size: 2.4rem;
    font-weight: 600;
    margin-right: 0.5rem;
    small {
      font-size: 1.3rem;
      color: rgb(170, 170, 170);
    }
  }

  strong {
    display: block;
  }
`

const CurrentLearnerStatistics = props => {
  return (
    <Container>
      <Statistic>
        <span>{props.performance}%</span> <strong>Performance</strong>
      </Statistic>
      {props.rankViewable && (
        <Statistic>
          <span>
            {props.rank} <small>/ {props.rankOf}</small>
          </span>{" "}
          <strong>Rank</strong>
        </Statistic>
      )}
      <Statistic>
        <span>{props.longest_streak}</span>
        <strong>Longest streak</strong>
      </Statistic>
    </Container>
  )
}

export default CurrentLearnerStatistics
