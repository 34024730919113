import React from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@apollo/client"
import Loading from "mill/components/Loader"
import currentUserLoaded from "mill/containers/CurrentUserLoaded"
import Heading from "mill/components/Heading"
import Imgix from "mill/components/Imgix"
import { paleBlue } from "mill/utils/colors"
import CurrentLearnerStatistics from "mill/components/CurrentLearnerStatistics"
import usePageTitle from "mill/hooks/usePageTitle"
import useTrackPage from "mill/hooks/useTrackPage"
import FETCH_QUESTIONS_COMPLETE_DATA from "mill/graphql/FetchQuestionsCompleteData"
import {
  Grid,
  SplitContainer,
  SplitHalf,
  ActionableTitle,
  ActionableWrapper
} from "mill/utils/shared/core"
import { md } from "mill/utils/breakpoints"
import {
  LearningLibraryCard,
  PreviousQuestionsCard,
  ViewDashboardCard
} from "mill/components/ActionableCard"

import styled from "styled-components"

const NegativePull = styled.div`
  margin-top: -3rem;
  background: white;
  border-radius: 3px;
  position: relative;
  padding: 0.1rem 2rem;
  @media ${md} {
    margin-top: 0;
    padding: 0;
  }
`

const QuestionsComplete = () => {
  const { campaignId } = useParams()
  usePageTitle("Questions complete!")
  useTrackPage("Questions Complete")
  const { data, loading, error } = useQuery(FETCH_QUESTIONS_COMPLETE_DATA, {
    variables: { campaignId }
  })

  if (loading) return <Loading />
  if (error) return <p>Error :(</p>

  const {
    viewerCampaignMembership: {
      performance,
      rank,
      individualRankViewable,
      longestStreak
    },
    campaign: {
      mechanism,
      learnerCount
    },
    viewer: { learningLibraryEnabled }
  } = data

  return (
    <Grid flow="column">
      <SplitContainer>
        <SplitHalf hasImage backgroundColor={paleBlue}>
          <Imgix
            srcPath={"static/sweet_moves.png"}
            parameters={"fit=fill&fill-color=0000"}
            maxWidth={640}
            maxHeight={480}
          />
        </SplitHalf>
        <SplitHalf hasText>
          <NegativePull>
            <Heading size={1} rank={1}>
              Sweet Moves!{" "}
              <small>You've answered all questions for today.</small>
            </Heading>
          </NegativePull>
          <div>
            <CurrentLearnerStatistics
              performance={performance}
              rank={rank}
              rankOf={learnerCount}
              rankViewable={individualRankViewable}
              longest_streak={longestStreak}
            />
            <ActionableWrapper>
              <ActionableTitle>Next Steps</ActionableTitle>
              <ViewDashboardCard
                campaignID={campaignId}
                mechanism={mechanism}
              />
              {learningLibraryEnabled && <LearningLibraryCard />}
              <PreviousQuestionsCard />
            </ActionableWrapper>
          </div>
        </SplitHalf>
      </SplitContainer>
    </Grid>
  )
}

export default currentUserLoaded(QuestionsComplete)
